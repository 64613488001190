html {
    background-color: #fff;
    margin: 0;
    padding: 0;
}

body {
    background-color: #fff;
    color: #333333;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    margin: 0;
    padding: 0;
}

.smallBold {
    font-size: 12px;
    font-weight: bold;
}

.small {
    font-size: 12px;
}

.big-icon {
    font-size: 130px;
}

a {
    color: #336fb7;
    outline: none;
    /*padding-right: 3px;*/
}

a:link,
a:visited,
a:active {
    color: #336fb7;
}

a:hover {
    color: #28578f;
}

.blue-color {
    color: #336fb7;
}

.grey-color {
    color: #5c5c5c;
}

.green-color {
    color: green;
}

.orange-color {
    color: orange;
}

.red-color {
    color: red;
}

.navbar-default .navbar-nav>li.selected-tab>a {
    color: #336fb7;
}

.navbar-default .navbar-nav>li.selected-tab>a:hover {
    color: #333;
}

header,
footer,
hgroup,
nav,
section {
    display: block;
}

mark {
    background-color: #a6dbed;
    padding-left: 5px;
    padding-right: 5px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear-fix:after {
    content: ".";
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #5c5c5c;
    margin-bottom: 0;
    padding-bottom: 0;
}

h1 {
    font-size: 36px;
    font-weight: normal;
}

.title h2 {
    font-size: 18px;
    color: #333333;
}

h2 {
    font-size: 20px;
    color: #336fb7;
    padding-bottom: 10px;
}

h3 {
    font-size: 14px;
    background-color: #336fb7;
    color: #fff;
    padding-left: 10px;
    line-height: 30px;
    font-weight: normal;
}

h4 {
    font-size: 14px;
    color: #336fb7;
}

h5,
h6 {
    font-size: 18px;
}

h5 a:link,
h5 a:visited,
h5 a:active {
    padding: 0;
    text-decoration: none;
}

img {
    page-break-inside: avoid;
}

img {
    max-width: 100% !important;
}

.description {
    color: #333333;
}

.bold {
    font-weight: bold;
}

.underline {
    border-bottom: 1px solid #5c5c5c;
}

.width100 {
    width: 100%;
}

.center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.container-nettregister-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
}

.container-nettregister {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

/* mustache
----------------------------------------------------------*/

.mustache-root-tag {
    display: none;
}



/* main layout
----------------------------------------------------------*/
.logo {
    float: left;
    margin-top: -12px;
    margin-right: 10px;
}

.logo-grey {
    float: left;
    margin-top: -8px;
    margin-right: 5px;
}

.topnav {
    padding-top: 18px;
    padding-left: 20px;
}

.content-wrapper {
    margin: 0 auto;
    /*max-width: 960px;*/
    /*max-width: 980px;*/
}

#body {
    background-color: #fff;
    clear: both;
    padding-bottom: 35px;
}




header .content-wrapper {
    padding-top: 20px;
}

footer {
    clear: both;
    background-color: #f6f6f6;
    color: #333;
    height: 100px;
    padding-top: 30px;
    border-top: #d0d0d0 solid 1px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.grey-background {
    background-color: #f6f6f6;
}


/* site title
----------------------------------------------------------*/
.site-title {
    color: #c8c8c8;
    font-size: 24px;
    margin: 0;
}

.site-title a,
.site-title a:hover,
.site-title a:active {
    background: none;
    color: #f0ebea;
    outline: none;
    text-decoration: none;
}

/* menu
----------------------------------------------------------*/
ul#menu {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 5px;
    padding: 0;
    text-align: right;
}

ul#menu li {
    display: inline;
    list-style: none;
    padding-left: 10px;
}

ul#menu li a {
    background: none;
    color: #f6f6f6;
    text-decoration: none;
}

ul#menu.level2 li a {
    color: #336fb7;
}

ul#menu.level2 li a:hover,
ul#menu li a:hover {
    color: #000;
}

ul#menu .selected-tab>a,
ul#menu.level2 .selected-tab>a {
    color: #336fb7;
    text-decoration: none;
}


ul#menu li .create,
ul#menu li .open,
ul#menu li .delete,
ul#menu li .details,
ul#menu li .edit {
    background-repeat: no-repeat;
    font-size: 12px;
    color: #5c5c5c;
    display: inline-block;
}

ul#menu .menu-item {
    display: none;
}

ul#menu .menu-item.selected-tab {
    display: inline;
}

ul#menu .selected-tab>a {
    display: none;
}

ul#menu .sub-menu .selected-tab>a {
    display: inline;
}

ul#menu .memberduplicate a {
    display: inline;
}

ul#menu .sub-menu {
    padding-left: 0px;
}



#menu .sub-menu {
    position: absolute;
    left: 0;
    white-space: nowrap;
    margin-left: 10px;
    top: 20px;
    width: 100%;
    text-align: left;
    margin-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f6f6f6;
    margin-top: 10px;
    margin-bottom: 10px;
}

#menu .sub-menu a {
    font-size: 16px;
    padding-left: 5px;
}

#menu .sub-menu .glyphicons {
    padding-right: 10px;
}

#menu.level2 {
    margin-bottom: 26px;
    position: relative;
}

#menu.level2.nochildren {
    margin-bottom: 5px;
}


.sub-menu-item.membership.selected-tab a {
    font-weight: bold;
}

.sub-menu-item.membership {
    margin-left: 2px;
}

.glyphicon,
.glyphicons {
    padding-right: 3px;
}

.glyphicons {
    padding-top: 1px;
}

.member-tab-header .glyphicons-family,
.memberrelation-tab .glyphicons-family,
.member-tab-header .glyphicons-group {
    padding-left: 5px;
    padding-right: 8px;
}

/*Dropdown menu*/
.dropdown-menu>li form a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu>li form a:hover {
    background-color: #f6f6f6;
    text-decoration: none;
}

/*.report-list {
    padding-top:10px;
}*/

/* page elements
----------------------------------------------------------*/
/* featured */
.featured {
    background-color: #fff;
}

.featured .content-wrapper {
    background-color: #fff;
    color: #3e5667;
    padding: 10px 0 10px 0;
}

.featured hgroup.title h1,
.featured hgroup.title h2 {
    color: #333;
}

.featured p {
    font-size: 1.1em;
}

/* page titles */
hgroup.title {
    margin-bottom: 10px;
}

hgroup.title h1,
hgroup.title h2 {
    display: inline;
}

hgroup.title h2 {
    font-weight: normal;
    margin-left: 3px;
}

/* features */
section.feature {
    width: 300px;
    float: left;
    padding: 10px;
}

/* ordered list */
ol.round {
    list-style-type: none;
    padding-left: 0;
}

ol.round li {
    margin: 25px 0;
    padding-left: 45px;
}


/* content */
article {
    float: left;
    width: 70%;
}

aside {
    float: right;
    width: 25%;
}

aside ul {
    list-style: none;
    padding: 0;
}



/* login page */
#loginForm {
    border-right: solid 2px #c8c8c8;
    float: left;
}

#loginForm .validation-error {
    display: block;
    margin-left: 15px;
}

#loginForm .validation-summary-errors ul {
    margin: 0;
    padding: 0;
}

#loginForm .validation-summary-errors li {
    display: inline;
    list-style: none;
    margin: 0;
}

#loginForm input {
    width: 250px;
}

#loginForm input[type="checkbox"],
#loginForm input[type="submit"],
#loginForm input[type="button"],
#loginForm button {
    width: auto;
    background-color: #336fb7;
}

.btn-default-nr {
    width: auto;
    background-color: #336fb7;
    color: #FFF;
}

#socialLoginForm {
    margin-left: 40px;
    float: left;
}

#socialLoginForm h2 {
    margin-bottom: 5px;
}

#socialLoginList button {
    margin-bottom: 12px;
}

#logoutForm {
    display: inline;
}

/* contact */
.contact h3 {
    font-size: 1.2em;
}

.contact p {
    margin: 5px 0 0 10px;
}

.contact iframe {
    border: 1px solid #5c5c5c;
    margin: 5px 0 0 10px;
}

/* forms */
fieldset.details-member {
    padding-top: 0;
    padding-bottom: 20px;
}


.details-member .table-root {
    min-height: 300px;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

fieldset legend {
    display: none;
}

fieldset ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

fieldset ol li {
    padding-bottom: 10px;
}

label {
    display: block;
    font-size: 14px;
    font-weight: 600;
}

label.checkbox {
    display: inline;
}

input,
textarea {
    /*border: 1px solid #e2e2e2;
    background-color: #336fb7;
    color: #5c5c5c;
    font-size: 14px;
    margin: 5px 0 6px 0;
    padding: 5px;*/
}

.dataTables_filter input {
    /*display: block;*/
    /*height: 34px;*/
    padding: 6px 12px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

textarea {
    font-family: inherit;
    width: 500px;
}

input:focus,
textarea:focus {
    border: 1px solid #0091d2;
}

input[type="checkbox"] {
    background: transparent;
    border: inherit;
    width: auto;
}

input[type="submit"],
input[type="button"],
button {
    background-color: #336fb7;
    border: 1px solid #2e6da4;
    cursor: pointer;
    padding: 4px;
    margin-right: 8px;
    width: 130px;
    color: #FFF;
    border-radius: 3px;
}

input[type="submit"]:hover {
    background-color: #28578f;
    border-color: #1d3f67;
}

.recommending-member input[type="submit"] {
    border: 0;
    background-color: #FFF;
    font-weight: normal;
    float: left;
    margin: 0;
    padding: 0;
    color: #7ac0da;
    text-decoration: underline;
}

td input[type="submit"],
td input[type="button"],
td button {
    font-size: 1em;
    padding: 4px;
    margin-right: 4px;
}

/* info and errors */
.message-info {
    border: 1px solid;
    clear: both;
    padding: 10px 20px;
}

.message-error {
    clear: both;
    color: #e80c4d;
    font-size: 1.1em;
    font-weight: bold;
    margin: 20px 0 10px 0;
}

.message-success {
    color: #7ac0da;
    font-size: 1.3em;
    font-weight: bold;
    margin: 20px 0 10px 0;
}

.error {
    color: #e80c4d !important;
}

/* styles for validation helpers */
.field-validation-error {
    color: #e80c4d;
    font-weight: bold;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #e80c4d;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    color: #e80c4d;
    font-weight: bold;
    font-size: 1.1em;
    padding-top: 30px;
}

.validation-summary-valid {
    display: none;
}


/* tables
----------------------------------------------------------*/
table {
    border-collapse: collapse;
    border-spacing: 0;
    /*margin-top: 0.75em;*/
    border: 0 none;
}

th {
    font-size: 14px;
    text-align: left;
    border: none 0;
    padding-left: 0;
}

th a {
    display: block;
    position: relative;
}

th a:link,
th a:visited,
th a:active,
th a:hover {
    color: #5c5c5c;
    font-weight: 600;
    text-decoration: none;
    padding: 0;
}

th a:hover {
    color: #000;
}

th.asc a,
th.desc a {
    margin-right: .75em;
}

th.asc a:after,
th.desc a:after {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.75em;
}

th.asc a:after {
    content: '▲';
}

th.desc a:after {
    content: '▼';
}

/*td {
    padding: 5px 5px 5px 0px;
    border: 0 none;
}*/

tr.pager td {
    padding: 0 0.25em 0 0;
}


/********************
*   Mobile Styles   *
********************/
@media only screen and (max-width: 850px) {

    /* header
    ----------------------------------------------------------*/
    header .float-left,
    header .float-right {
        float: none;
    }

    /* logo */
    header .site-title {
        margin: 10px;
        text-align: center;
    }

    /* menu */
    nav {
        margin-bottom: 5px;
    }

    ul#menu {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    ul#menu li {
        margin: 0;
        padding: 0;
    }


    /* main layout
    ----------------------------------------------------------*/
    .main-content,
    .featured+.main-content {
        background-position: 10px 0;
    }

    .content-wrapper {
        padding-right: 10px;
        padding-left: 10px;
    }

    .featured .content-wrapper {
        padding: 10px;
    }

    /* page content */
    article,
    aside {
        float: none;
        width: 100%;
    }

    /* ordered list */
    ol.round {
        list-style-type: none;
        padding-left: 0;
    }

    ol.round li {
        padding-left: 10px;
        margin: 25px 0;
    }

    ol.round li.zero,
    ol.round li.one,
    ol.round li.two,
    ol.round li.three,
    ol.round li.four,
    ol.round li.five,
    ol.round li.six,
    ol.round li.seven,
    ol.round li.eight,
    ol.round li.nine {
        background: none;
    }

    /* features */
    section.feature {
        float: none;
        padding: 10px;
        width: auto;
    }

    section.feature img {
        color: #999;
        content: attr(alt);
        font-size: 1.5em;
        font-weight: 600;
    }

    /* forms */
    input {
        width: 90%;
    }

    /* login page */
    #loginForm {
        border-right: none;
        float: none;
        width: auto;
    }

    #loginForm .validation-error {
        display: block;
        margin-left: 15px;
    }

    #socialLoginForm {
        margin-left: 0;
        float: none;
        width: auto;
    }


    /* footer
    ----------------------------------------------------------*/
    footer .float-left,
    footer .float-right {
        float: none;
    }

    footer {
        text-align: center;
        height: auto;
        padding: 10px 0;
    }

    footer p {
        margin: 0;
    }
}

/*Custom layouts*/

header {
    /*background-color: #735959;*/
    background-color: #5c5c5c;
    height: 80px;
}

.navbar {
    min-height: 60px;
}

.navbar-default {
    background-color: #f6f6f6;
    border-bottom: 1px solid #d0d0d0;
}

.navbar-nav>li>a {
    padding-top: 19px;
    padding-bottom: 0;
}

.navbar-brand {
    padding-top: 19px;
    padding-bottom: 15px;
}

.topheader.navbar-default {
    background-color: #5c5c5c;
}

.topheader.navbar-default .navbar-nav>li>a {
    color: #f6f6f6;
}

.topheader.navbar-default .navbar-brand {
    color: #f6f6f6;
}

.header {
    border-top: 1px solid #bdbdbd;
}

.header-big-text {
    color: #5c5c5c;
    font-size: 14px;
    font-style: italic;
}

.headertablestatus {
    width: 100%;
    color: #5c5c5c;
}


.topheader-loggedin .header-big-text {
    color: #333;
    font-size: 14px;
    font-style: italic;
}

.topheader-loggedin .headertablestatus {
    width: 100%;
    color: #333;
}


.member-list-header .dataTables_processing {
    display: none;
}

.member-list-header .dataTables_processing thead {
    display: none;
}

.member-list .paginate_enabled_next {
    padding-left: 10px;
}

/*Edit member*/
.required {
    color: red;
    float: left;
    padding-left: 4px;
}

/*Detailmember*/
.labelmember {
    font-weight: bold;
}


/*Utlisting*/
.tbl-adr {
    width: 150px;
}

.tbl-unit {
    width: 100px;
}

.webpartHeading {
    font-size: 16px;
    color: #735959;
    font-weight: bold;
}


.member-list {
    width: 100%;
    margin-top: 10px;
}

.member-list select {
    margin-top: 5px;
}

#members_table {
    width: 100%;
}

#members_table .sorting,
#units_table .sorting {
    cursor: pointer;
    font-weight: normal;
    color: #336fb7;
}

#members_table th:hover,
#units_table th:hover {
    cursor: pointer;
    color: #336fb7;
}

#members_table th.sorting_desc,
#members_table th.sorting_asc,
#units_table th.sorting_desc,
#units_table th.sorting_asc {
    color: #336fb7;
}

#members_table th.sorting_asc:before {
    content: "\21E7";
    margin-right: 5px;
}

#members_table th.sorting_desc:before {
    content: "\21E9";
    margin-right: 5px;
}

#members_table .sorting:hover,
#units_table .sorting:hover {
    cursor: pointer;
    text-decoration: underline;
}

#members_table .tbl-phone {
    width: 150px;
}

.field-zipname,
.field-name {
    text-transform: capitalize;
}

.field-address:first-letter {
    text-transform: uppercase;
}

.field-documents,
.field-comments {
    text-align: center;
    width: 20px;
}

.field-external-member-id {
    text-align: center;
    width: 20px;
}



.odd {
    background-color: #f0f0f0;
    cursor: pointer;
}


#members_table tr:hover,
#members_table tr:hover a,
#units_table tr:hover,
#units_table tr:hover a {
    background-color: #f6f6f6;
    cursor: pointer;
    color: #333;
}

#members_table thead tr:first-child,
#units_table thead tr:first-child {
    border: 1px solid transparent;
    background-color: #f6f6f6;
    border-bottom: 1px solid #d0d0d0;
}


#members_table tr,
#units_table tr {
    border: 1px solid transparent;
    height: 35px;
}

#members_table .field-name,
#units_table .field-externalid {
    padding-left: 10px;
}

.item.selected,
.odd.selected,
.even.selected {
    background-color: #336fb7;
    color: #FFF;
}

.item.selected a,
.odd.selected a,
.even.selected a {
    color: #FFF;
}

.dataTables_length {
    float: right;
}

.dataTables_filter {
    float: left;
}



.create-document-container.dataTables_processing {
    padding: 0;
    background-size: 70px;
}


/*Contact*/
.contact header {
    height: 35px;
    padding-left: 10px;
    color: #ffffff;
    /*background-color: #7ac0da;*/
    background-color: #5c5c5c;
}

.contact .label {
    background-color: #fff;
    color: #000;
    text-shadow: 0 0 0 #000000;
}

/*footermenu*/
.footermenu {
    clear: left;
}

.footermenu ul#menu li {
    padding-left: 0;
}

/*Details member*/
.member-name {
    /*border-bottom: #d0d0d0 solid 1px;*/
}

.details-member label {
    font-weight: bold;
    clear: left;
    float: left;
}

.details-member ol li {
    padding-bottom: 0;
}

.details-member ol,
.details-member ul {
    margin-left: 0;
}

/*.details-member td {*/
.report-list .advanced-filter td {
    min-width: 190px;
    height: 40px;
}

.details-member td {
    min-width: 190px;
    height: 40px;
}

/*.details-member .btn {
    width: 100%;
}*/

.report-columns {
    padding: 10px;
}

.details-member-view td {
    min-width: 190px;
    height: 23px;
}


/*.dashboard #members_table_dublett td, #members_table_inprocess td, #members_table_recommending_person td, #members_table_status td {*/
.dashboard td {
    height: 30px;
}

#members_table td {
    min-width: 5px;
}

.details-member-name {
    font-size: 32px;
}

div.details-member-name {
    margin-bottom: 10px;
}

.goto-membership {
    float: right;
}

.memberinfo-left {
    clear: left;
    float: left;
    width: 50%;
}

.memberinfo-right {
    float: left;
    width: 50%;
}

.details-member-edit {
    clear: left;
}

.details-member-status {
    font-weight: normal;
    display: block;
    font-size: 14px;
    padding-top: 10px;
}

.details-member-changed {
    font-weight: normal;
    display: block;
    font-size: 12px;
    font-style: italic;
}

.table-header {
    font-size: 14px;
    font-weight: bold;
    color: #336fb7;
    margin-top: 20px;
    margin-bottom: 15px;
    display: block;
    position: relative;
}

/*
.memberinfo-left .td-label, .memberinfo-right .td-label {
    width: 115px;
}
    */
.td-content textarea {
    padding: 4px 6px;
    width: 206px;
}

/*
.search .memberinfo-left .td-label, .search .memberinfo-right .td-label {
    width: 130px;
}
    */
.ui-autocomplete {
    list-style: none;
    padding-left: 10px;
}

.ui-widget-content a {
    color: #222222
    /*{fcContent}*/
    ;
    font-family: Arial;
}

.clear-left {
    clear: left;
}

.width50 {
    width: 50%;
}

.memberrelation-container {
    padding-bottom: 20px;
}

.new-document {
    width: 185px !important;
}


.comments .item,
.documents .item,
.groupmembership-container,
.groupMemberships .item,
.audits .item {
    padding: 10px;
    border-bottom: #d0d0d0 solid 1px;
}

.comments .container-header,
.documents .container-header,
.groupmembership-container,
.groupMemberships .container-header,
.audits .container-header {
    padding: 10px;
    border-bottom: #d0d0d0 solid 1px;
    border-bottom: 0;
    padding-bottom: 0;
}

.comments .item:last-child,
.documents .item:last-child,
.groupMemberships .item:last-child,
.audits .item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.documents .fa {
    padding-left: 1px;
}

.documents ul.tagit {
    margin-bottom: 0px;
    font-size: 12px;
}

.member-tab-list,
.tab-list {
    border-bottom: 1px solid #d0d0d0;
}

.member-tab-list,
.tab-list {
    padding-top: 10px;
    padding-bottom: 8px;
}

.member-tab-header,
.tab-header {
    margin-right: 4px;
    padding: 10px;
    color: #336fb7;
    cursor: pointer;
    margin-bottom: -1px;
}

.tab-content {
    padding-top: 10px;
}

.active-tab {
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-color: transparent;
}

.member-tab-header:hover {
    background-color: #f6f6f6;
    border-radius: 4px 4px 0 0;
}

.member-tab-content {
    padding-top: 8px;
}

/*Details_organisation*/
.details-organisation-name {
    font-size: 24px;
    font-weight: bold;
}

div.details-organisation-name {
    margin-bottom: 20px;
}

/*knapper*/
button,
.new-memberrelation,
.new-comment,
.searchbox,
.new-document,
.new-invoice,
input[type="button"] {
    background-color: #336fb7;
    border-color: #2e6da4;
    color: #FFF;
    width: 130px;
}

button:hover,
.new-memberrelation:hover,
.new-comment:hover,
.searchbox:hover,
.new-document:hover,
.new-invoice:hover,
input[type="button"]:hover {
    background-color: #28578f;
    border-color: #1d3f67;
}

input.cancelbutton {
    background-color: #5c5c5c;
    border-color: #5c5c5c;
}

input.cancelbutton:hover {
    background-color: #7c7c7c;
    border-color: #7c7c7c;
}



/*Comment-container*/
/*.comment-container, .memberrelation-container, .document-container, .groupmembership-container,*/
.invoice-container,
.membershiptype-container,
.group-container,
.membershipstatus-container {
    margin-top: 10px;
    margin-bottom: 10px;
    border: #d0d0d0 solid 1px;
    background-color: #fff;
    border-radius: 4px;
}

.memberRelations,
.comments,
.documents,
.groupMemberships,
.audits,
.membership-at-same-address-list {
    margin-top: 10px;
    padding-bottom: 10px;
    border: #d0d0d0 solid 1px;
    background-color: #fff;
    border-radius: 4px;
}

.membership-at-same-address-list {
    padding-bottom: 30px;
}


.memberRelations {
    padding-bottom: 20px;
}

/*Report fields-container*/
.fields-container {
    border-top: #d0d0d0 solid 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
}

.unit-container .fields-container {
    min-height: 200px;
}

.fields-container .memberinfo-left,
.fields-container .memberinfo-right {
    padding-top: 20px;
}

.report-submit {
    padding-left: 20px;
}

.fields-container textarea {
    width: 100%;
}

/*Memberrelation-container*/


/*Innmeldingsskjema*/
.innmeldingsskjema {
    font-family: camberia;
}

.innmeldingsskjema-header {
    font-size: 18px;
    font-weight: bold;
}

.innmeldingsskjema-smallheader {
    font-size: 14px;
    font-weight: bold;
}

.innmeldingsskjema-kursiv {
    font-style: italic;
    padding-left: 5px;
}

.innmeldingsskjema-bold {
    font-weight: bold;
}

.contact .label-required {
    float: left;
}

.contact .textbox {
    clear: left;
}

.marginTop10 {
    margin-top: 10px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginLeft10 {
    margin-left: 10px;
}

.padding10 {
    padding: 10px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop30 {
    padding-top: 20px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingLeft25 {
    padding-left: 25px;
}

.paddingTop40 {
    padding-top: 40px;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.paddingBottom20 {
    padding-bottom: 20px;
    margin-left: 0;
}

.paddingBottom30 {
    padding-bottom: 30px;
}

.paddingBottom40 {
    padding-bottom: 40px;
}

.print-skjema {
    margin: 20px;
    width: 780px;
}

.innmeldingsskjema-table {
    border-style: solid;
    border-width: 1px;
    border-bottom-width: 0;
}

.innmeldingsskjema-table div {
    border-bottom-style: solid;
    border-width: 1px;
}

.innmeldingsskjema-table-section {
    height: 25px;
    margin-top: 5px;
}

.innmeldingsskjema-label {
    display: inline-block;
    width: 250px;
    font-weight: bold;
    padding-left: 10px;
}

.innmeldingsskjema-label-nopadding {
    display: inline-block;
    width: 250px;
    font-weight: bold;
}

.innmeldingsskjema-footer-section {
    text-align: center;
}

.attest {
    text-align: center;
}

.invoive-total {
    font-weight: bold;
    font-size: 14px;
    border-top: 1px solid;
}

/*Newsletter*/
.advanced-filter {
    background-color: #F0EBEA;
    z-index: 100;
}



.expand-toggle:hover {
    color: #000;
}



.preview-expand-toggle:hover {
    color: #000;
}


.preview-toggle-container {
    border: 1px #336fb7 solid;
    padding-left: 10px;
}

.markItUp {
    width: 100% !important;
    border: 0 !important;
}

.markItUpContainer {
    width: 100% !important;
}

.markItUpEditor {
    background: none !important;
    border: #5c5c5c 2px solid !important;
    padding: 10px !important;
    font-family: Arial !important;
}

.markItUpHeader select {
    font-size: 11px;
}

.markItUpHeader .merge select {
    height: 20px;
    margin: 0;
    vertical-align: top;
    width: auto;
}

div.identity-ok,
span.identity-ok,
input.identity-ok,
select.identity-ok {
    border: 1px solid green;
}

input.identity-other {
    border: 1px solid red;
}

input.identity-own {
    border: 1px solid orange;
}


.expand-toggle-0 {
    cursor: pointer;
}

/*Report*/



.search td {
    padding: 0;
}

.search label {
    font-size: 14px;
}


/* -------------------------------------------------------------------
// markItUp! Universal MarkUp Engine, JQuery plugin
// By Jay Salvat - http://markitup.jaysalvat.com/
// ------------------------------------------------------------------*/

.markItUpContainer {
    border: 1px solid #336fb7 !important;
    background: none !important;
    padding: 5px 5px 2px 5px;
    font: 11px Verdana, Arial, Helvetica, sans-serif;
}

.markItUpEditor {
    font: 12px 'Courier New', Courier, monospace;
    padding: 5px 5px 5px 35px;
    border: 3px solid #5c5c5c;
    /*width: 643px;*/
    width: 95% !important;
    height: 320px;
    background: none !important;
    clear: both;
    line-height: 18px;
    overflow: auto;
}



.role-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 40px;
}

.role-list p {
    margin: 0;
}

/*index html*/

s.innhold a {
    text-decoration: none;
    color: #5c5c5c;
}

.funk-picture {
    align-content: center;
    text-align: center;
    height: 130px;
    padding-top: 20px;
}

/*SMS*/
.sms .markItUpContainer {
    border: none !important;
    background: #F5F5F5 !important;
    padding: 5px 5px 2px 5px;
    font: 11px Verdana, Arial, Helvetica, sans-serif;
}

.smstable {
    margin-top: 10px;
}

.editor-body {
    padding-top: 10px;
    padding-left: 5px;
}

.details-member .editor-body {
    width: 100%;
}

.sms .markItUpHeader {
    padding-bottom: 10px !important;
    height: 25px;
}

.sms .markItUpEditor {
    font-family: Arial, Verdana, Helvetica, Sans-Serif !important;
    font-size: 14px !important;
    min-height: 150px;
    height: auto;
}

.navbar-header button {
    width: auto;
}

/*END*/

/* Multiple Membership */
.related .field-recommending-person,
.main .field-recommending-person {
    display: none;
}

.related .field-addressline1,
.main .field-addressline1 {
    display: none;
}

.related .field-zipcode,
.main .field-zipcode {
    display: none;
}

.related .field-zipname,
.main .field-zipname {
    display: none;
}

.related .field-status,
.main .field-status {
    display: none;
}

.related .field-telephone1,
.main .field-telephone1 {
    display: none;
}

.related .field-membershiptype,
.main .field-membershiptype {
    display: none;
}

.related .field-fromdate,
.main .field-fromdate {
    display: none;
}

.related .action-buttons,
.main .action-buttons {
    display: none;
}

.related .table-header.membership,
.main .table-header.membership,
.common .table-header.membership {
    display: none;
}

.related .table-header.contact,
.main .table-header.contact,
.common .table-header.contact {
    display: none;
}

.related .table-header.member,
.main .table-header.member,
.common .table-header.member {
    display: none;
}

.common .field-identity {
    display: none;
}

.common .field-categorytype {
    display: none;
}

.common .field-firstname {
    display: none;
}

.common .field-lastname {
    display: none;
}

.common .field-middlename {
    display: none;
}

.common .field-email {
    display: none;
}

.common .field-telephone2 {
    display: none;
}

.common .field-memberrelationtype,
.main .field-memberrelationtype {
    display: none;
}

/*Multicreate*/
.add-memberrelation {
    cursor: pointer;
    margin-top: 10px;
}

.delete-item {
    cursor: pointer;
    margin-top: 10px;
}

.btn-add-memberrelation,
.btn-delete-memberrelation {
    width: 150px;
    border: 1px solid #ccc;
    padding: 6px 12px;
    margin-bottom: 0;
    border-radius: 4px;
}

.btn-delete-memberrelation {
    width: 170px;
}

.btn-add-memberrelation:hover,
.btn-delete-memberrelation:hover {
    background-color: #efefef;
}


/*END*/

#ui-datepicker-div {
    z-index: 5 !important;
}


#members_table .item .checkable:before {
    content: "\f096";
    /* fa-square-o */
    font-family: FontAwesome;
}

#members_table .item.selected .checkable:before {
    content: "\f046";
    /* fa-check-square-o */
    font-family: FontAwesome;
}

#members_table .field-check.checkable.selected:before {
    content: "\f046";
    /* fa-check-square-o */
    font-family: FontAwesome;
}

#members_table .field-check.checkable.mixed:before {
    content: "\f146";
    /* fa-minus-square-o */
    font-family: FontAwesome;
}

#members_table .field-check.checkable:before {
    content: "\f096";
    /* fa-square-o */
    font-family: FontAwesome;
}

#members_table .item .checkable,
#members_table .field-check.checkable {
    min-width: 20px;
    text-align: center;
}

.member-list {
    position: relative;
}

.member-list .DTTT_container {
    background-clip: padding-box;
    background-color: transparent;
    float: left;
    display: none;
    font-size: 14px;
    left: 9px;
    list-style: outside none none;
    margin: 2px 0 0;
    min-width: 33px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 40px;
    z-index: 1000;
}

.member-list .DTTT_container .select_all_button span::before {
    content: "\f096";
    /* fa-check-square-o */
    font-family: FontAwesome;

}

.member-list .DTTT_container .deselect_all_button span::before {
    content: "\f046";
    /* fa-check-square-o */
    font-family: FontAwesome;

}

/*END*/

/* Tabs*/

.tabset {
    padding: 0 0 20px;
}

.tabs {
    height: 39px;
}

.no-js .tabs {
    display: none;
}

.tab {
    border: 3px solid black;
    float: left;
    line-height: 30px;
    margin: 0 3px 0 0;
    padding: 0 10px;
}

.tab-active {
    border-color: red;
    cursor: default;
}

.tabcontentwrapper {
    position: relative;
}

.tabcontentdiv {
    border: 3px solid black;
    display: none;
    padding: 10px;
    text-align: center;
    position: relative !important;
}

.tabcontentdiv-active {
    display: block;
}

.no-js .tabcontentdiv {
    display: block;
}

/*END*/

/* Message box*/
.callback-status {
    display: none;
    padding: 5px;
    width: 100%;
}

.async-success {
    border: 3px solid green;
}

.async-fail {
    border: 3px solid red;

}



/*Membership suggestions*/
#membership-at-same-address-list {
    padding-top: 10px;
}

.membership-at-same-address-list .table-header {
    margin-top: 0px;
}

/*Duplikater*/

#member_Dublicates_filter {
    padding-bottom: 20px;
}

.memberDuplicates td {
    padding-top: 6px;
    padding-bottom: 6px;
}

.dataTables_info {
    padding-top: 10px;
}

.paginate_button {
    padding-right: 10px;
}

.dt-button-collection {
    width: 200px;
    margin-top: 3px;
    padding: 10px 10px 4px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: #FFF;
    overflow: hidden;
    border-radius: 0px;
}

/*END*/

/* Openstreet Map override */
#OpenLayers_Map_2_OpenLayers_Container {
    height: inherit;
    width: inherit;
}

.olControlAttribution {
    bottom: 0 !important;
}

/*END*/

/* React Datepicker */
.react-datepicker-popper {
    /*z-index: 99 !important;*/
}

.react-datepicker {
    display: flex !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    display: inherit !important;
}

.membership-container .input-group .form-control,
.membership-edit .input-group .form-control {
    z-index: 0;
}

.membership-container .input-group-addon {
    padding-right: 7px;
}

.report-page .header,
.communication-page .header {
    border-top: 0;
}

.paddingBottom20 a:link,
.paddingBottom20 a:visited,
.paddingBottom20 a:active {
    color: #FFF;
}

.flex-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.margin-h2 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.spinner {
    display: block;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 50%;
}

.info-message {
    display: block;
    position: absolute;
    z-index: 10;
    padding: 10px;
    background-color: #f6f6f6;
    border: 1px solid #5c5c5c;
    border-radius: 4px;
}

input[type="button"].in-progress {
    cursor: progress;

}

.form-group .input-error {
    border: 1px solid red;
    border-radius: 4px;
}

.input-group input.form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.input-group.select-group,
.input-group .select-group {
    display: block;
}

.select-group .select-group__indicator {
    padding: 8px 11px;
}

.form-control.identity-identitytype {
    padding-left: 2px;
    padding-right: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.form-control.identity-gender {
    padding-left: 2px;
    padding-right: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.col-md-2.identity-gender,
.col-md-3.identity-gender {
    padding-left: 0;
}

.field-identity .col-md-4.identity-identitytype {
    width: 30%;
}

.field-identity .col-md-2.identity-gender {
    width: 20%;
}

.identity-identitypart.red-color {
    border: 1px solid red;
}

.leaflet-container {
    width: 100%;
    height: 150px;
}

.validation-summary {
    border-radius: 7px;
    border: solid #e80c4d 2px;
    padding-top: 5px;
    padding-top: 5px;
}

.validation-item {
    color: #e80c4d;
    font-weight: bold;
}

.react-images-upload.fileContainer {
    margin: 0;
    padding: 10px 0;
}

.react-images-upload img {
    height: 200px;
    width: 150px;
}

.sticky-close-button {
    position: absolute;
    cursor: pointer;
    display: block;
    border: none;
    top: -3px;
    right: 16px;
    width: 26px;
    height: 26px;
    background: rgb(214, 0, 0);
    color: white;
    padding: 0;
    z-index: 2;
    border-radius: 50%;
    outline: none;
}

.sticky-close-button-icon {
    padding: 1px;
}

/*button {
    appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}*/